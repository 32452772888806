
import { GoogleOutlined } from "@ant-design/icons-vue";
import { defineComponent, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import { isPossibleWebview } from "@/shared/utils/browser";

export default defineComponent({
  props: {
    loading: Boolean,
  },
  setup() {
    const { t } = useI18n();

    const isPossibleWebviewRef = ref(false);

    onMounted(() => {
      isPossibleWebviewRef.value = isPossibleWebview();
    });

    return {
      t,
      isPossibleWebviewRef,
    };
  },
  components: {
    GoogleOutlined,
  },
});
