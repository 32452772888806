import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, mergeProps as _mergeProps, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_GoogleOutlined = _resolveComponent("GoogleOutlined")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_typography_text = _resolveComponent("a-typography-text")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_a_button, _mergeProps({
      type: "primary",
      class: "google-btn normal-letter-spacing"
    }, _ctx.$attrs, {
      loading: _ctx.loading,
      disabled: _ctx.isPossibleWebviewRef
    }), {
      icon: _withCtx(() => [
        _createVNode(_component_GoogleOutlined)
      ]),
      default: _withCtx(() => [
        _createTextVNode(" " + _toDisplayString(_ctx.t("Sign in with Google")), 1)
      ]),
      _: 1
    }, 16, ["loading", "disabled"]),
    (_ctx.isPossibleWebviewRef)
      ? (_openBlock(), _createBlock(_component_a_typography_text, {
          key: 0,
          class: "google-btn-webview-warning"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.t(
        "Google-Sign In will not work for pop-up browsers. Please open this website in your desktop or mobile browser application (Google Chrome, Safari, Mozilla Firefox, etc.)"
      )), 1)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ], 64))
}